<template>
  <div class="home">     
    <v-card color="light-grey mx-auto"
    >
      <v-card-title>Kate , Tim and Marty And Joel Podcast Library</v-card-title>
      <v-card-subtitle>From 2016 - 2020</v-card-subtitle>
      <v-card-text>
      <p>This is a podcast library for Kate , Tim and Marty And Joel for shows published. </p>
    <p>The podcast list has been extracted from the podcast rss feed used by Apple Itune.</p>
    <p>The podcasts on this website can also be listen to on the <a href="https://www.novafm.com.au/shows/kate-tim-joel/">Nova website</a>.
    The difference is that on the nova page or on the acast podcast app, you have to scoll endlessly to find the show you are interested in.
    </p>

    <v-alert
      border="right"
      colored-border
      type="info"
      elevation="2"
    >
    <b>Example</b><br />If you want to listen to the show when Tim was sick and Marty Panelled the show, it was on 02 March 2020.
    </v-alert>
    <v-card            
            outlined
            tile
          >
          <v-card-title>Navigation - Using the Menu</v-card-title>          
        <v-card-text>
          Use the navigation links on the left of the page. <br />All the podcast for a given month can be loaded by clicking on the month menu option
          <br />
    Podcasts are then listed in decesending order, from the end of the month to the begining of the month.
      </v-card-text>
    </v-card>
    <v-card            
            outlined
            tile
            class="my-3"
          >
          <v-card-title>Navigation - Using the Search Box</v-card-title>          
        <v-card-text>
    <p>You can also search for a specific show using the searh box on the top of the page.<br />
             For example you can seach for all the shows with Karl, by using the search term "Karl" in the search box.</p>
      </v-card-text>

    </v-card>

      <v-alert        
        type="info"
        elevation="2"
      >
      <b>PS</b><br />This is for my own personal listening pleasure, it is not a commercial application.<br  />
      It has no affiliation with novafm.
      </v-alert>
      </v-card-text>
    </v-card>
<br />
  <v-card
    class="mx-auto"
    max-width="434"
    tile
  >
    <v-img
      height="100%"
      src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"
    >
      <v-row
        align="end"
        class="fill-height"
      >
        <v-col
          align-self="start"
          class="pa-0"
          cols="12"
        >
          <v-avatar
            class="profile"
            color="grey"
            size="164"
            tile
          >
            <v-img src="profile.jpg"></v-img>
          </v-avatar>
        </v-col>
        <v-col class="py-0">
          <v-list-item
            color="rgba(0, 0, 0, .4)"
            dark
          >
            <v-list-item-content>
              <v-list-item-title class="title">
               <a href="mailto:ktmj.library@deltastateonline.com">ktmj.library</a>
              </v-list-item-title>
              <v-list-item-subtitle>Support</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-img>
  </v-card>




  </div>
</template>

<script>
import {mapGetters  } from 'vuex';

export default {
  name: 'Info',
  components: {    
    
  },
  data(){
    return {
      podcastList :[]
    }
  },
  computed : mapGetters(['allPodcasts','podcastListLoading']),
  methods : {
    
  },
  created (){   
    this.$store.dispatch('setPodcastLoading',true);
  }


}
</script>
