<template>
  <div class="about">
    <span class="text-h6">Podcast for {{podcastdate}}</span>
    <v-container class="my-1">
      <v-progress-linear v-show="podcastListLoading"
      color="success"
      indeterminate
      stream
      ></v-progress-linear>
      <v-row>      
      <Podcast v-for="aPodcast in allPodcasts" :key="aPodcast.ID" v-bind:podcastObject="aPodcast"></Podcast>
      </v-row>
    </v-container>
   </div>
</template>


<script>
import {mapGetters , mapActions } from 'vuex';
import Podcast from "@/components/Podcast.vue";

export default {
  name: 'AllPodcastGroups',
  components: {
    Podcast
  },
  data(){
    return {
      podcastdate :"Date"
    }
  },
  computed : mapGetters(['allPodcasts','podcastListLoading']),
  watch: {
      podcastdate : function(val){          
          this.$store.dispatch('fetchPodcastsByDate',val);
      }
  },
  methods : {
    ...mapActions([])
  },
  created (){
    this.podcastdate = this.$route.params.podcastdate;
    this.$store.dispatch('setPodcastLoading',true);
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next() 
    this.podcastdate = to.params.podcastdate;
    next();    
  }
}
</script>